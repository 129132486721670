import React from 'react'

export const CiscenjeObjekataNamestaja = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Čišćenje objekata i nameštaja</h2>
                    <p className="mb-4">Vršimo čišćenje objekata i dubinsko čišćenje nameštaja, koristeći profesionalne metode i sredstva za čistoću.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/free-photo/blurred-woman-using-fluffy-duster_23-2148520982.jpg?w=740&t=st=1692869647~exp=1692870247~hmac=024b1c5e21b5410f219a91b26f2af61ebd23dfe76fea9fa0c45aad19c29503c5" alt="Ciscenje Objekata" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/premium-photo/applying-detergent-sofa-home-interior-dry-cleaning-smearing-detergent-couch-using-drill-with-brush-dry-cleaning_255847-11749.jpg?w=740" alt="Ciscenje Namestaja" />
                </div>
            </div>
        </section>
    </div>
  )
}
