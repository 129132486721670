import React from "react";
import {useRef, useState} from "react";
import emailjs from '@emailjs/browser';

const Kontakt = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0ukuf8t', 'template_jzzdy3h', form.current, 'Dd6ifxhHgIOKHvCIZ')
      .then((result) => {
          console.log(result.text);
          setMessageSent(true);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <div className="bg-white dark:bg-gray-900 py-24">
      <section className=" grid grid-cols-1 md:grid-cols-2  max-w-screen-xl mx-auto">
        <div className="w-2/3 mx-auto">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Kontaktirajte nas!
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-justify text-gray-500 dark:text-gray-400 sm:text-xl">
            Ako želite saznati više o našim uslugama ili zakazati konsultacije,
            slobodno nas kontaktirajte. Radujemo se što ćemo vam se javiti i
            postati vaš partner od poverenja za sve potrebe brige o vašoj
            imovini i stilu života.
          </p>
          <div className="text-start md:text-center">
            <p className="font-bold  text-gray-500 dark:text-gray-400 sm:text-xl">
              Kontakt telefoni:
            </p>
            <p className=" font-light  text-gray-500 dark:text-gray-400 sm:text-xl">
              Srbija: +069 3717015
            </p>
            <p className="mb-8 font-light  text-gray-500 dark:text-gray-400 sm:text-xl">
              Svajcarska: +41 76 4439744
            </p>
            <p className="font-bold text-gray-500 dark:text-gray-400 sm:text-xl">
              Kontakt mejl:
            </p>
            <a href="mailto:office@vasprijatelj.com" className="mb-1 font-light  text-gray-500 dark:text-gray-400 sm:text-xl hover:text-blue-300 hover:underline">
              office@vasprijatelj.com
            </a>
          </div>
          
        </div>
        <div className="py-12 md:py-0 px-4 mx-auto w-3/4">
          <form action="#" className="space-y-8" ref={form} onSubmit={sendEmail}>
            <div>
              <label
                htmlFor="email" 
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                E-mail: 
              </label>
              <input
                type="email"
                id="email" name="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject" 
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Naslov:
              </label>
              <input
                type="text" name="subject"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message" 
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Poruka:
              </label>
              <textarea
                id="message" name = "message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-16 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Pošalji
            </button>
          </form>
          {messageSent && (
            <div className="mt-4 text-center text-green-600 dark:text-green-400">
              Uspešno ste poslali poruku.
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Kontakt;
