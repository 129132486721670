import React from 'react'

export const PronalazenjeMajstora = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Pronalaženje pouzdanih majstora</h2>
                    <p className="mb-4">Omogućavamo vam da pronađete majstore od poverenja za razne usluge kao što su moleraj, parketarstvo i sanitarni radovi.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/free-photo/carpenter-creating-new-furniture_329181-3765.jpg?w=740&t=st=1692869718~exp=1692870318~hmac=837a2cac012d242c86b7b9d8387dc8181dad737241cd14f4cfb58b7949ee5856" alt="Pouzdani majstor" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/free-photo/skillful-carpenter-wrapped-up-work_1098-15330.jpg?w=740&t=st=1692869720~exp=1692870320~hmac=00f10c352c69563ea84fe657f3bd3386b9529f0a4d47b566c3a22176f5567091" alt="Pronalazenje majstora" />
                </div>
            </div>
        </section>
    </div>
  )
}
