'use client';

import React from 'react'
import { Carousel } from 'flowbite-react';

export default function CarouselIndex() {
  return (
    <section className="bg-white dark:bg-gray-900 h-64 md:h-96">

    <Carousel slideInterval={5000}>
      <img
        alt="Kosenje trave"
        src="slider1.jpeg"
      />
      <img
        alt="Kucna administracija"
        src="slider2.jpg"
      />
      <img
        alt="Dubinsko ciscenje"
        src="slider3.jpg"
      />
    </Carousel>
    </section>
    
  )
}

