import React from 'react'

export const OdrzavanjeDvoristaKuca = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Održavanje dvorišta i kuća</h2>
                    <p className="mb-4">Pružamo usluge održavanja dvorišta, obilaska kuća i vršimo potrebne popravke kako biste se osećali sigurno i udobno.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/free-photo/construction-worker-assembling-furniture_329181-11785.jpg?w=740&t=st=1692869613~exp=1692870213~hmac=d295b21ee7d42a0a57505c58f060e13d1218959f74802b778a662dc7c1298257" alt="Odrzavanje dvorista" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/free-photo/busy-man-using-weedwacker-garden_329181-20572.jpg?w=740&t=st=1692869582~exp=1692870182~hmac=24346d5c38993278dacf8b207256274d9aa0ed12cc8b48809abc67fd3f864b9a" alt="Odrzavanja kuca" />
                </div>
            </div>
        </section>
    </div>
  )
}
