import './App.css';
import Pocetna from "./pages/Pocetna/Pocetna"
import Kontakt from "./pages/Kontakt/Kontakt"
import ONama from "./pages/ONama/ONama"
import PaketiUsluga from "./pages/PaketiUsluga/PaketiUsluga"
import {Header} from "./components/Header"
import {Footer} from "./components/Footer"
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import "@fortawesome/fontawesome-svg-core/styles.css";
import { KucnaAdministracija } from './pages/PaketiUsluga/KucnaAdministracija';
import { SlanjePrimanjePoklona } from './pages/PaketiUsluga/SlanjePrimanjePoklona';
import { PosiljkaCvecaPoklona } from './pages/PaketiUsluga/PosiljkaCvecaPoklona';
import { ObilazakObjekata } from './pages/PaketiUsluga/ObilazakObjekata';
import { UslugeSoppinga } from './pages/PaketiUsluga/UslugeSoppinga';
import { OdrzavanjeDvoristaKuca } from './pages/PaketiUsluga/OdrzavanjeDvoristaKuca';
import { CiscenjeObjekataNamestaja } from './pages/PaketiUsluga/CiscenjeObjekataNamestaja';
import { PronalazenjeMajstora } from './pages/PaketiUsluga/PronalazenjeMajstora';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Pocetna/>,
    },
    {
      path: "/kontakt",
      element: <Kontakt/>
    },
    {
      path: "/onama",
      element: <ONama/>
    },
    {
      path: "/usluge",
      element: <PaketiUsluga/>
    },
    {
      path:"/kucna-administracija",
      element: <KucnaAdministracija/> 
    },
    {
      path:"/slanje-i-primanje-poklona",
      element: <SlanjePrimanjePoklona/>
    },
    {
      path: "/posiljka-cveca-poklona",
      element: <PosiljkaCvecaPoklona/>
    },
    {
      path: "/obilazak-objekata",
      element: <ObilazakObjekata/>
    },
    {
      path: "/usluge-shoppinga",
      element: <UslugeSoppinga/>
    },
    {
      path: "/odrzavanje-dvorista-kuca",
      element: <OdrzavanjeDvoristaKuca/>
    },
    {
      path: "/ciscenje-objekata-namestaja",
      element: <CiscenjeObjekataNamestaja/>
    },
    {
      path: "/pronalazenje-majstora",
      element: <PronalazenjeMajstora/>
    }

  ])
  return (
    <div className="App">
     <Header/>
        <RouterProvider router={router}/>
     <Footer/>
    </div>
  );
}

export default App;
