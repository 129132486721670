import React from 'react'

export const ObilazakObjekata = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Obilazak objekata</h2>
                    <p className="mb-4">Nudimo obilazak vaših objekata kako bismo osigurali da sve bude na svom mestu posebno posle vremenskih nepogoda. Da budete sigurni da je sve na svom mestu.
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="obilazak-objekata1.jpg" alt="Obilazak objekata" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/free-photo/cute-couple-holding-lamp_23-2147782380.jpg?w=740&t=st=1692869360~exp=1692869960~hmac=92cfe6b9555ba84e23e4ef13c4c204044bc320af1c10f24e11296d14e3b69aff" alt="Pregled objekata" />
                </div>
            </div>
        </section>
    </div>
  )
}
