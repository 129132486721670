import React from 'react'

export const UslugeSoppinga = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Usluge šopinga</h2>
                    <p className="mb-4">Preuzimamo nabavku namirnica i drugih potrepština prema vašim zahtevima, štedeći vam vreme i trud.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/free-photo/medium-shot-woman-holding-product_23-2149148504.jpg?w=740&t=st=1692869499~exp=1692870099~hmac=58e95ed8ebc1a81e5b50a2c6c5be2f12d2ba0611ac8b9391c363c0125e00b0d3" alt="Usluge shopinga" />
                    <img className="mt-10 w-full lg:mt-20 rounded-lg" src="https://img.freepik.com/premium-photo/nice-nurse-bringing-vegetables-old-patient-home_107420-10399.jpg?w=740" alt="Nabavka namirnica" />
                </div>
            </div>
        </section>
    </div>
  )
}
