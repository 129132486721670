import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faSquareFacebook,
} from "@fortawesome/free-brands-svg-icons";


export const Footer = () => {
  return (
    <footer class="dark:bg-gray-900 bg-gray-900 dark:border-t dark:border-gray-500" >
      <div class="mx-auto w-full max-w-screen-xl ">
        <div class="grid grid-cols-1 text-start md:text-center  gap-10 px-4 py-6 lg:py-8 md:grid-cols-3">
          <div>
            <h2 class="mb-6 text-lg  font-semibold text-white uppercase dark:text-white">
              O Nama <hr class="mt-2 text-center w-1/3 md:w-1/2 md:mx-auto"></hr>
            </h2>
            <p class="text-white text-justify md:text-center">
              "Vaš Prijatelj" je kompanija posvećena pružanju širokog spektra
              usluga koje olakšavaju svakodnevne aktivnosti i poboljšavaju
              kvalitet života naših klijenata. Naš cilj je da postanemo vaš
              pouzdan partner, omogućavajući vam da živite bezbrižno i sa više
              vremena za stvari koje zaista volite.
            </p>
          </div>
          <div>
            <h2 class="mb-6 text-lg font-semibold text-white uppercase dark:text-white">
              Radno vreme <hr class="mt-2 text-center w-1/3 md:w-1/2 md:mx-auto"></hr>
            </h2>
            <ul class=" dark:text-gray-400 font-medium text-white">
                <li>
                    <p>Ponedeljak - Subota - 8:00 - 20:00</p>
                    <p class="mb-3">Nedelja - zatvoreno</p>
                </li>
              <li class="mb-4">
                <div className="">
                  <a >
                    <p>
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "#ffffff" }}
                      />
                      {"  "}
                      Srbija: +069 3717015
                    </p>
                    <p>
                      {"  "}
                      Svajcarska: +41 76 4439744
                    </p>
                  </a>
                </div>

                <div className="mt-3">
                  <a class="hover:underline " href="mailto:office@vasprijatelj.com">
                    <p>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "#ffffff" }}
                      />
                      {"  "}
                      office@vasprijatelj.com
                    </p>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-lg font-semibold text-white uppercase dark:text-white ">
              Zapratite nas <hr class="mt-2 text-center w-1/3 md:w-1/22 md:mx-auto"></hr>
            </h2>
            <p class="text-white ">
              Za više novosti posetite nas i na društvenim mrežama!
            </p>
            <ul class="text-gray-500 dark:text-gray-400 font-medium ml-13 mt-2">
              <li>
                <a href="https://www.facebook.com/vas_prijatelj/" class="mr-4 hover:underline md:mr-6 text-white">
                  <FontAwesomeIcon
                    icon={faSquareFacebook}
                    style={{ color: "#ffffff" }}
                  />
                  {"  "}
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/vas_prijatelj/" class="mr-4 hover:underline md:mr-6 text-white">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "#ffffff" }}
                  />
                  {"  "}
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
