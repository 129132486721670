'use client'

import React from "react";
import { Navbar, Dropdown } from 'flowbite-react';

export const Header = () => {
  return (
    <Navbar class=" bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700"
      fluid
    >
      <div class="max-w-screen-xl w-full flex flex-wrap items-center justify-between mx-auto p-4">
      <Navbar.Brand
        href="https://vasprijatelj.com" className="flex items-center"
      >
        <img
          alt="Vas Prijatelj Logo"
          className="h-12 mr-3"
          src="logonavbar.png"
        />
      </Navbar.Brand>
      <Navbar.Toggle 
      className="btn cursor-pointer inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"/>
      <Navbar.Collapse>
        <Navbar.Link
          href="/"
          class="block text-sm md:my-2 py-3 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-300 
          md:hover:bg-transparent md:border-0 md:hover:text-[#0FBD06] md:p-0 dark:text-white
           md:dark:hover:text-[#0FBD06] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          <p>
            Početna
          </p>
        </Navbar.Link>
        <Navbar.Link
          href="/onama"
          class="block text-sm md:my-2 py-3 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-300 
          md:hover:bg-transparent md:border-0 md:hover:text-[#0FBD06] md:p-0 dark:text-white
           md:dark:hover:text-[#0FBD06] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          <p>
            O Nama
          </p>
        </Navbar.Link>
        <Navbar.Link class="block py-1 pl-1 text-lg text-gray-900 rounded-lg hover:bg-gray-300 
        md:hover:bg-transparent md:border-0 md:hover:text-[#0FBD06] md:p-0 md:m-0 dark:text-white
         md:dark:hover:text-[#0FBD06] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >
          <Dropdown label="Usluge" class="mx-auto md:p-0 md:m-0 " placement="bottom">
            <Dropdown.Item href="/kucna-administracija"  class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Kućna Administracija
            </Dropdown.Item>
            <Dropdown.Item href="/slanje-i-primanje-poklona" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Slanje i primanje poklona
            </Dropdown.Item>
            <Dropdown.Item href="/posiljka-cveca-poklona" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Slanje cveća i torta u Vaše ime
            </Dropdown.Item>
            <Dropdown.Item href="/obilazak-objekata" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Obilazak objekata
            </Dropdown.Item>
            <Dropdown.Item href="/usluge-shoppinga" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Usluge Shoppinga
            </Dropdown.Item>
            <Dropdown.Item href="/odrzavanje-dvorista-kuca" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Održavanje dvorišta i kuća
            </Dropdown.Item>
            <Dropdown.Item href="/ciscenje-objekata-namestaja" class="block text-sm px-4 py-2 w-full border-b border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
             Čišćenje objekata i nameštaja
            </Dropdown.Item>
            <Dropdown.Item href="/pronalazenje-majstora" class="block text-sm px-4 py-2 w-full  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Pronalaženje pouzdanih majstora
            </Dropdown.Item>
          </Dropdown>
        </Navbar.Link>

        <Navbar.Link href="/usluge" 
        class="block text-sm md:py-2 py-3 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-300 
        md:hover:bg-transparent md:border-0 md:hover:text-[#0FBD06] md:p-0 dark:text-white
         md:dark:hover:text-[#0FBD06] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
          Paketi Usluga
        </Navbar.Link>
        <Navbar.Link href="/kontakt"
        class="block text-sm md:py-2 py-3 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-300 
        md:hover:bg-transparent md:border-0 md:hover:text-[#0FBD06] md:p-0 dark:text-white
         md:dark:hover:text-[#0FBD06] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
          Kontakt
        </Navbar.Link>
      </Navbar.Collapse>
      </div>
    </Navbar>
  )
}



