import React from 'react'

export default function WhyUs() {
  return (
    <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Zašto odabrati nas?</h2>
            <div class="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                <div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Poverenje i Prijateljstvo:
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400 text-justify">
                            Poverenje je u srcu naših vrednosti. Gradimo odnose koji idu izvan poslovnih, tretirajući vaše potrebe kao da su naše. Mi smo vaša proširena porodica i iskreni prijatelj u vašoj odsutnosti.
                        </p>
                    </div>
                    <div class="mb-10">                        
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        Ekspertiza i Posvećenost:
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400 text-justify">
                            Iako smo porodična firma, ponosno sarađujemo sa renomiranim kompanijama i stručnjacima. Naša strast i predanost su pokretači izvanredne usluge. Vaša imovina je poverena u ruke iskusnih profesionalaca koji zaista brinu.
                        </p>
                    </div>
                </div>
                <div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        Prilagođena Rešenja: 
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400 text-justify">
                        Razumemo da je svaka imovina jedinstvena, kao i vaše potrebe. Naše usluge su prilagođene vašim specifičnim zahtevima, obezbeđujući personalizovano iskustvo koje vas potpuno zadovoljava.
                        </p>
                    </div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Više smo od običnog startapa:
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400 text-justify">
                            Naša porodično vođena firma posvećena je tome da vam olakša i učini život ugodnijim, omogućavajući vam da živite bez briga.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
  )
}
