import React from 'react'

export const SlanjePrimanjePoklona = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Slanje Poklona u Vase ime</h2>
                    <p className="mb-4">Pružamo uslugu slanja personalizovanih poklona i cveća, torti ili drugih proizvoda u ime naših klijenata.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/premium-photo/deliveryman-uniform-parcels-car-cargo-delivery-service-man-standing-cardboard-packages-vehicle-male-deliver_266732-21488.jpg?w=740" alt="Slanje poklona" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/free-photo/young-woman-wrapping-christmas-presents_144627-36116.jpg?w=740&t=st=1692869146~exp=1692869746~hmac=d811e0e30c0479c09e11a0037d2abf64017f24d2ac07f402d7f1f36ea30bb443" alt="Primanje poklona" />
                </div>
            </div>
        </section>
    </div>
  )
}
