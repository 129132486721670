import React from 'react'
import Hero from '../../components/Hero';
import Services from '../../components/Services';
import WhyUs from '../../components/WhyUs';
 const Pocetna = () => {
  return (
    <main className='bg-white  dark:bg-gray-900'>
    <Hero />
    <Services />
    <div class="relative z-0 w-full">
                    <div class="relative h-96 overflow-hidden border-b border-gray-300 dark:border-gray-700 max-w-screen-xl mx-auto">
                      <img src="slider1.jpeg" class="absolute brightness-50 block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Vas prijatelj Odrzavanje Nekretnina"/>
                    </div>
                    <div class="absolute z-9 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                        <h2 class="text-3xl md:text-3xl lg:text-5xl text-gray-100 font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Za život bez stresa!</h2>
                        <p class="text-xl pt-12 md:text-3xl lg:pt-24 lg:text-3xl text-gray-100 font-normal drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">"Vaš Prijatelj"</p> 
                    </div>
                </div>
    <WhyUs />
      <div class="mr-auto place-self-center lg:col-span-7 pb-24 bg-white dark:bg-gray-900">
          <a href="/kontakt" class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
              Kontaktirajte nas
          </a>
          <a href="/usluge" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
              Pogledajte Usluge
              <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </a>
      </div>
    </main>
  )
}

export default Pocetna;