import React from 'react'

const ONama = () => {
  return (
    <section class="bg-white  dark:bg-gray-900">
      <hero>
            <div class="relative z-0 w-full ">
                    <div class="relative h-56 overflow-hidden md:h-96 border-b border-gray-300 dark:border-gray-700 max-w-screen-xl mx-auto">
                      <img src="https://theturfgrassgroup.com/wp-content/uploads/2021/04/When-to-Start-Mowing-in-the-Spring-1024x683.jpeg" class="absolute brightness-50 block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                    </div>
                    <div class="absolute z-9 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                        <h1 class="text-3xl md:text-5xl lg:text-7xl text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">O Nama</h1>
                        <p class="text-xl md:text-3xl lg:text-5xl w-full text-gray-300 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Vaši čuvari imovine i stila života</p>
                    </div>
                </div>
          </hero>
      <main>
        <div className="mx-auto py-12 max-w-screen-md">
          <section class="border-b border-gray-300 py-12 text-start">
            <h2 className='text-2xl font-bold mx-12 md:mx-3 text-center dark:text-gray-200 text-gray-900'>
                VAŠ PRIJATELJ
            </h2>
            <p class="font-semibold dark:font-normal text-justify text-md mx-12 pt-5 dark:text-gray-300 text-gray-900"> Vaš pouzdani partner u brizi o nekretninama i životnom stilu.
            Vaš pouzdani partner u brizi o nekretninama i životnom stilu. "Vaš Prijatelj" je porodicna firma posvećena pružanju širokog spektra usluga koje olakšavaju svakodnevne aktivnosti i poboljšavaju kvalitet života naših klijenata.
             Naš cilj je da postanemo vaš pouzdan partner, omogućavajući vam da živite bezbrižno i sa više vremena za stvari koje zaista volite.
            </p>
          </section>
          <section class="border-b border-gray-300 py-12 text-start">
            <h2 className='text-2xl font-bold mx-12 md:mx-3 dark:text-gray-200 text-gray-900'>
                Naša misija:
            </h2>
            <p class="font-semibold text-md text-justify dark:font-normal mx-12 pt-5 dark:text-gray-300 text-gray-900"> 
            Naša misija je pružiti visokokvalitetne usluge brige o nekretninama i načinu života na koje se naši klijenti mogu osloniti.
             Razumemo važnost poverenja i pouzdanosti kada je u pitanju upravljanje imovinom i nastojimo biti partner na kojeg naši
              klijenti mogu računati. Posvećeni smo pružanju personalizirane usluge koja zadovoljava jedinstvene potrebe svakog klijenta.
            
            </p> 
            <p class="font-semibold text-md text-justify dark:font-normal mx-12 pt-5 dark:text-gray-300 text-gray-900">
            Bilo da se radi o obilasku imovine, održavanju vrta i bazena, održavanju i čišćenju kuće ili nega automobila,
             ponosni smo na pružanje vrhunskih usluga koje našim klijentima osiguravaju bezbrižnost.
              Naš cilj je život naših klijenata učiniti lakšim i ugodnijim, vodeći računa o detaljima kako oni to ne bi morali činiti.
            </p>
          </section>
          <section class="py-12 ">
            <h2 className='text-2xl font-bold text-start mx-12 md:mx-3 dark:text-gray-200 text-gray-900'>
                  Usluge:
              </h2>
              <ul className='pt-5 mx-12 font-normal dark:marker:text-white list-disc text-start  dark:text-gray-300 text-gray-900'>
                  <li>
                      <span class="font-bold">Kućna Administracija: </span>
                      <p className='text-sm  mb-2 dark:text-gray-300 text-gray-900'>
                        Pružamo usluge praznjenja postanskog sanduceta, plaćanje računa... mi stojimo u redu za vas. 
                      </p>
                  </li>
                  <li>
                      <span class="font-bold">Slanje Poklona u Vase ime: </span>
                      <p className='text-sm mb-2 dark:text-gray-300 text-gray-900'>
                        Pružamo uslugu slanja personalizovanih poklona i cveća, torti ili drugih proizvoda u ime naših klijenata.
                      </p>
                  </li>
                  <li>
                       <span class="font-bold">Usluge Shopinga:</span>
                      <p className='text-sm mb-2  dark:text-gray-300 text-gray-900'>
                       Preuzimamo nabavku namirnica i drugih potrepština prema vašim zahtevima, štedeći vam vreme i trud.
                      </p>
                  </li>
                  <li>
                      <span class="font-bold">Održavanje Dvorišta i Kuća: </span>
                      <p className='text-sm  mb-2 dark:text-gray-300 text-gray-900'>
                      Pružamo usluge održavanja dvorišta, obilaska kuća i vršimo potrebne popravke kako biste se osećali sigurno i udobno.
                      </p>
                  </li>
                  <li>
                      <span class="font-bold">Čišćenje Objekata i Nameštaja:</span>
                      <p className='text-sm mb-2 dark:text-gray-300 text-gray-900'>
                       Vršimo čišćenje objekata i dubinsko čišćenje nameštaja, koristeći profesionalne metode i sredstva za čistoću.
                      </p>
                  </li>
                  <li>
                      <span class="font-bold">Ponuda Izvlačenja Vlage:</span>
                      <p className='text-sm mb-2 dark:text-gray-300 text-gray-900'>
                        Naša usluga se fokusira na rešavanje problema vlage u vašim prostorijama. Koristeći napredne profesionalne mašine i ekspertizu, efikasno izvlačimo vlagu, čime obezbeđujemo suve zidove i podove unutrasnjih prostora.  
                      </p>
                  </li>
                  <li>
                      <span class="font-bold">Pronalaženje Pouzdanih Majstora:</span>
                      <p className='text-sm mb-2 dark:text-gray-300 text-gray-900'>
                       Omogućavamo vam da pronađete majstore od poverenja za razne usluge kao što su moleraj, parketarstvo i sanitarni radovi.
                      </p>
                  </li>
              </ul>
          </section>
          <section class="border-b border-gray-300 pt-3 pb-12 text-justify">
            <p class="font-semibold dark:font-normal text-md mx-12 pt-5 dark:text-gray-300 text-gray-900">
            "Vaš Prijatelj" je više od običnog startapa.
            </p>
            <p class="font-semibold dark:font-normal text-md mx-12 pt-5 dark:text-gray-300 text-gray-900">
            To je obećanje brige, zaštite i poverenja. 
            Naša porodična firma posvećena je tome da vam olakša i učini život ugodnijim,
            omogućavajući vam da živite bez briga. Kao vaši pouzdani čuvari,
            ozbiljno shvatamo odgovornosti koje nam poveravate i osiguravamo da vaša imovina ostane utočište
            čak i kad niste prisutni. Doživite nivo usluge koji je više od poslovnog - to je prijateljstvo.
            </p> 
            <p class="font-semibold dark:font-normal text-md mx-12 pt-5 dark:text-gray-300 text-gray-900">
            "Vaš Prijatelj" je osmišljen kako bi vaš svakodnevni život postao lakši i udobniji.
             Naša posvećenost kvalitetu, poverenju i profesionalizmu čini nas idealnim izborom za sve vaše potrebe.
              Dozvolite nam da budemo deo vašeg života i omogućimo vam da spavate mirno, znajući da je sve pod kontrolom.
            </p>
          </section>
          <section class="border-b border-gray-300 pt-3 pb-12 text-justify">
            <p class="font-semibold dark:font-normal text-md mx-12 pt-5 dark:text-gray-300 text-gray-900">
            Naša Vrednost
            </p>
            <p class="font-semibold dark:font-normal text-md mx-12 pt-5 dark:text-gray-300 text-gray-900">
            Nudimo sveobuhvatan pristup svim uslugama na jednom mestu.
            Dostavljamo detaljne račune, slike i video snimke kako bismo vam omogućili da budete potpuno informisani
            i sigurni u kvalitet našeg rada. Vaša udobnost, sigurnost i zadovoljstvo su nam prioriteti.
            </p> 
          </section>
        </div>
      </main>
    </section>
  )
}

export default ONama;
