import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBook, faHouse, faGift, faCake, faBagShopping, faFaceSmile, faCar, faBroom } from "@fortawesome/free-solid-svg-icons";
import {
  faSearchengin,
} from "@fortawesome/free-brands-svg-icons";

export default function Services() {
  return (
  <section class="bg-white dark:bg-gray-900 mb-8">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
        <div class="max-w-screen-md mx-auto mb-8 lg:mb-16">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Usluge
            </h2>
            <p class="text-gray-500 sm:text-xl dark:text-gray-400 ">
            Naša misija je pružiti visokokvalitetne usluge brige o nekretninama i načinu života na koje se naši klijenti mogu osloniti.
            </p>
        </div>
        <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faBook}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold dark:text-white">
                    Kućna Administracija
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                Pružamo usluge praznjenja postanskog sanduceta, plaćanje računa... mi stojimo u redu za vas.
                </p>
                </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faGift}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold dark:text-white">
                    Slanje i Primanje Poklona
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                Olakšavamo proces slanja i primanja paketa, brinući se o svim detaljima kako biste bili sigurni da će vaši pokloni stići na vreme i sa pažnjom.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faCake}
                            style={{ color: "#006699" }}
                        />                         <path fill-rule="evenodd" 
                            d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path><path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z">
                        </path>
                </div>
                <h3 class="mb-2 text-xl font-bold dark:text-white">
                    Posiljke, Cveće i Torta u Vaše Ime
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                Nudimo usluge slanja cveća, torti i drugih poklona u vaše ime, uz mogućnost personalizacije i brige o svim aspektima dostave.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faFaceSmile}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold dark:text-white">Obilazak Objekata</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Nudimo obilazak vaših objekata kako bismo osigurali da sve bude na svom mestu posebno posle vremenskih nepogoda, da budete sigurni da je sve na svom mestu.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faBagShopping}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold dark:text-white">Usluge Shopinga</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Preuzimamo nabavku namirnica i drugih potrepština prema vašim zahtevima, štedeći vam vreme i trud.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faCar}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold  dark:text-white">Briga o Automobilu</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Vaše vozilo je produžetak vašeg načina života. Pre nego što se vratite sa odmora, osiguravamo da je vaš automobil čist kako spolja tako i unutra. Nema više povratka u prašnjavo ili zapušteno vozilo.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faHouse}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold  dark:text-white">Održavanje Dvorišta i Kuća</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Pružamo usluge održavanja dvorišta, obilaska kuća i vršimo potrebne popravke kako biste se osećali sigurno i udobno.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon
                            icon={faBroom}
                            style={{ color: "#006699" }}
                        />                 </div>
                <h3 class="mb-2 text-xl font-bold  dark:text-white">Čišćenje Objekata i Nameštaja</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Vršimo čišćenje objekata i dubinsko čišćenje nameštaja, koristeći profesionalne metode i sredstva za čistoću.
                </p>
            </div>
            <div>
                <div class="flex justify-center mx-auto items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                    <FontAwesomeIcon
                            icon={faSearchengin}
                            style={{ color: "#006699" }}
                        />                </div>
                <h3 class="mb-2 text-xl font-bold  dark:text-white">Pronalaženje Pouzdanih Majstora</h3>
                <p class="text-gray-500 dark:text-gray-400">
                Omogućavamo vam da pronađete majstore od poverenja za razne usluge kao što su moleraj, parketarstvo i sanitarni radovi.
                </p>
            </div>
        </div>
    </div>
</section>
  )
}
