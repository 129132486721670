import React from 'react'

export const PosiljkaCvecaPoklona = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Slanje cveća i torta u Vaše ime</h2>
                    <p className="mb-4">Nudimo usluge slanja cveća, torti i drugih poklona u vaše ime, uz mogućnost personalizacije i brige o svim aspektima dostave.</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://img.freepik.com/free-photo/woman-making-floral-bouquet_23-2148846526.jpg?w=740&t=st=1692869195~exp=1692869795~hmac=86a6efb8188e0261d9a991c25a8ff17b0690421c4d68d5b4395253a33a8cd17e" alt="Posiljka cveca" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://img.freepik.com/premium-photo/bunch-sweets-table-man-is-with-delicious-desserts-that-are-eco-boxes_146671-88575.jpg?w=740" alt="Slanje poklona" />
                </div>
            </div>
        </section>
    </div>
  )
}
