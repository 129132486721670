import React from 'react'

export const KucnaAdministracija = () => {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Kućna administracija</h2>
                    <p className="mb-4">Pružamo usluge praznjenja postanskog sanduceta, plaćanje računa... mi stojimo u redu za vas. </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full h-48 rounded-lg" src="kucna-administracija1.jpg" alt="Kucna Administracija" />
                    <img className="mt-4 h-48 w-full lg:mt-10 rounded-lg" src="kucna-administracija2.jpg" alt="Administrativni i pravni poslovi" />
                </div>
            </div>
        </section>
    </div>
  )
}
